<template>
    <v-sheet color="grey lighten-5" class="user-card rounded-sm pa-20 pa-md-40">
        <v-row align="center">
            <v-col cols="auto">
                <v-img src="/images/icon/icon-avatar.svg" class="user-card__avatar" />
            </v-col>
            <v-col>
                <div class="page-text font-weight-medium">
                    <span>{{ user?.name || "회원" }}</span>
                    <span>님 안녕하세요.</span>
                </div>
            </v-col>
        </v-row>
    </v-sheet>
</template>

<script>
import { mapState } from "vuex";
export default {
    computed: {
        ...mapState(["user"]),
    },
};
</script>

<style lang="scss" scoped>
.user-card {
    &__avatar {
        width: 60px;
    }
}
@media (min-width: 768px) {
    .user-card {
        &__avatar {
            width: 80px;
        }
    }
}
</style>