<template>
    <client-page>
        <!-- 서브비주얼 -->
        <sub-visual sh="마이페이지" />
        <mypage-navigation />

        <page-section class="page-section--first page-section--last">
            <user-card class="mb-18 mb-md-24" />

            <v-data-table v-bind="{ items, headers }" hide-default-footer disable-filtering disable-sort class="v-data-table--default table--primary--large v-data-table--no-hover">
                <template v-for="(header, index) in headers.filter((header) => header.hasOwnProperty('formatter'))" #[`item.${header.value}`]="{ value, item }"> <span :key="index" v-html="header.formatter.bind(item)(value)" /> </template>

                <template #[`item.cancel`]="{ item }">
                    <rental-request-cancel-btn :value="item" @input="updateItem" />
                </template>

                <template #[`item.state`]="{ item, value, header }">
                    <div class="font-weight-bold">
                        <template v-if="false"></template>

                        <template v-else-if="value == RENTAL_SCHEDULE_STATES.APPLIED.value">
                            <span class="grey--text">대기</span>
                        </template>
                        <template v-else-if="value == RENTAL_SCHEDULE_STATES.CANCELED.value">
                            <span class="red--text font-weight-medium">취소</span>
                        </template>
                        <template v-else-if="value == RENTAL_SCHEDULE_STATES.APPROVED.value">
                            <span class="primary--text">승인</span>
                        </template>
                        <template v-else-if="value == RENTAL_SCHEDULE_STATES.REJECTED.value">
                            <template v-if="!!item?.desc?.rejection">
                                <v-dialog max-width="480">
                                    <template #activator="{ attrs, on }">
                                        <span class="red--text font-weight-medium" v-bind="attrs" v-on="on">
                                            거절
                                            <v-icon small>mdi-information-outline</v-icon>
                                        </span>
                                    </template>
                                    <v-card>
                                        <v-card-title>거절사유</v-card-title>
                                        <v-card-text>
                                            {{ item.desc.rejection }}
                                        </v-card-text>
                                    </v-card>
                                </v-dialog>
                            </template>
                            <template v-else>
                                <span class="red--text font-weight-medium">거절</span>
                            </template>
                        </template>
                    </div>
                </template>
            </v-data-table>

            <div class="v-btn--group">
                <v-pagination v-model="page" :length="pageCount" total-visible="11" @input="(page) => $router.push({ query: { page } })" />
            </div>
        </page-section>
    </client-page>
</template>

<script>
import api from "@/api";
import { RENTAL_SCHEDULE_STATES, RENTAL_TYPES } from "@/assets/variables";

import ClientPage from "@/pages/client/templates/ClientPage.vue";

import SubVisual from "@/components/client/sub/sub-visual.vue";
import PageSection from "@/components/client/sub/page-section.vue";
import MypageNavigation from "@/components/client/mypage-navigation.vue";
import UserCard from "@/components/client/mypage/user-card.vue";

import RentalRequestCancelBtn from "@/components/client/rental/requests/rental-request-cancel-btn.vue";

export default {
    components: {
        ClientPage,

        SubVisual,
        PageSection,
        MypageNavigation,
        UserCard,

        RentalRequestCancelBtn,
    },
    data: () => ({
        requests: [],
        summary: { totalCount: 0 },

        limit: 10,

        loading: false,

        RENTAL_SCHEDULE_STATES,
    }),
    computed: {
        items() {
            return this.requests.map((item, index) => ({ ...item, index: this.summary.totalCount - (this.page - 1) * this.limit - index, isDone: this.$dayjs().isAfter(item.datetime) }));
        },
        headers() {
            let headers = [
                { text: "공간명", value: "subject.name", align: "left" },
                { text: "예약일", value: "date", width: "16%", formatter: (value) => (value ? this.$dayjs(value).format("YYYY.MM.DD (dddd)") : "-") },
                {
                    text: "예약시간",
                    value: "times",
                    width: "14%",
                    formatter: function (value = []) {
                        switch (this.rentalType) {
                            case RENTAL_TYPES.BY_DATE.value: {
                                return "종일";
                            }
                            case RENTAL_TYPES.BY_HOUR.value: {
                                let hours = [...(value || [])].sort((a, b) => +a - +b);
                                return `${hours.at(0)}:00 ~ ${hours.at(-1)}:59`;
                            }
                            case RENTAL_TYPES.BY_TIME.value: {
                                return value?.join?.("\n");
                            }
                        }
                    },
                    cellClass: "white-space-pre-line",
                },
                { text: "신청", value: "cancel", width: "12%" },
                { text: "상태", value: "state", width: "12%", formatter: (value) => RENTAL_SCHEDULE_STATES[value]?.text || value || "-" },
            ].map((item) => ({ ...item, align: item.align ?? "center" }));

            return headers;
        },
        page() {
            return +(this.$route.query.page || "1");
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        params() {
            const { ...query } = this.$route.query;

            delete query.page;

            return { ...query };
        },
    },
    methods: {
        async search() {
            if (this.loading) return;
            else this.loading = true;
            try {
                let { skip, limit, params } = this;
                let { summary, requests } = await api.v1.me.rental.requests.gets({
                    headers: { skip, limit },
                    params,
                });

                this.summary = summary;
                this.requests = requests;
            } finally {
                this.loading = false;
            }
        },
        updateItem(item) {
            let index = this.requests.findIndex(({ _id }) => _id == item._id);
            if (index > -1) {
                this.requests.splice(index, 1, item);
            } else {
                this.requests.splice(0, 0, item);
            }
        },
    },
    watch: {
        params() {
            this.search();
        },
        subjectType() {
            this.search();
        },
    },
    mounted() {
        this.search();
    },
};
</script>

<style lang="scss" scoped>
.v-data-table {
    > .v-data-table__wrapper {
        > table {
            > thead > tr > th,
            > tbody > tr > td {
                font-size: 14px;
            }
        }
    }
}
@media (min-width: 768px) {
    .v-data-table {
        > .v-data-table__wrapper {
            > table {
                > thead > tr > th,
                > tbody > tr > td {
                    font-size: 16px;
                }
            }
        }
    }
}
</style>
