<template>
    <div class="sub-tab">
        <v-container>
            <v-tabs :height="$vuetify.breakpoint.mdAndUp ? '64' : '52'" hide-slider>
                <template v-for="({ text, to }, index) in items">
                    <v-tab v-bind="{ to }" :key="index"> {{ text }} </v-tab>
                </template>
            </v-tabs>
        </v-container>
    </div>
</template>
<script>
import { RENTAL_SUBJECT_TYPES } from "@/assets/variables";

const items = [
    { text: "마이페이지 홈", to: "/mypage/home" },
    { text: "대관예약", to: "/mypage/reservations" },
    { text: "회원정보 수정", to: "/mypage/myinfo" },
    { text: "비밀번호 변경", to: "/mypage/password" },
    { text: "1:1 문의", to: "/mypage/inquiry" },
    { text: "회원탈퇴", to: "/mypage/withdrawal" },
    // { text: "메이커스 장비 예약 현황", to: `/mypage/rental-requests/${RENTAL_SUBJECT_TYPES.EQUIPMENTS.value}` },
    // { text: "시설 및 공간 예약 현황", to: `/mypage/rental-requests/${RENTAL_SUBJECT_TYPES.FACILITIES.value}` },
    // { text: "불참 기록", to: "/mypage/bans" },
];

export default {
    data: () => ({
        items,
    }),
};
</script>

<style lang="scss" scoped>
.sub-tab {
    &::after {
        display: none;
    }
}
</style>