<template>
    <div>
        <template v-if="isCanceled">
            <div class="red--text font-weight-medium">취소완료</div>
        </template>
        <template v-else-if="isCancelable">
            <v-btn small color="primary" rounded @click="cancel"><span class="font-size-12">신청취소</span></v-btn>
        </template>
        <template v-else>
            <v-btn small color="grey lighten-4" @click="alert"><span class="grey--text text--lighten-1 font-size-12">신청취소</span></v-btn>
        </template>
    </div>
</template>

<script>
import api from "@/api";
import { initRentalRequest, RENTAL_SCHEDULE_STATES } from "@/assets/variables";

export default {
    props: {
        value: { type: Object, default: initRentalRequest },
    },
    data: () => ({
        loading: false,
    }),
    computed: {
        isCanceled() {
            return this.value.state == RENTAL_SCHEDULE_STATES.CANCELED.value;
        },

        hasDatePassed() {
            return this.$dayjs(this.value.date).subtract(1, "day").hour(18).minute(0).isBefore(this.$dayjs());
        },

        isCancelable() {
            if (this.hasDatePassed) return false;
            if (this.value.state == RENTAL_SCHEDULE_STATES.CANCELED.value) return false;
            if (this.value.state == RENTAL_SCHEDULE_STATES.REJECTED.value) return false;
            if (!this.$dayjs().isBefore(this.value.date, "date")) return false;
            return true;
        },
    },
    methods: {
        async cancel() {
            const { _id, subject, schedules } = this.value;

            let go = confirm(`아래 예약에 대한 신청을 취소하시겠습니까?\n설비명: ${subject?.name}`);
            if (!go) return;

            if (this.loading) return;
            else this.loading = true;

            try {
                await Promise.all(
                    schedules.map(async (schedule = {}) => {
                        let { _id } = schedule;
                        try {
                            return { ...((await api.v1.me.rental.schedules.putCancel({ _id }))?.schedule || schedule), success: true };
                        } catch (error) {
                            console.error(error);
                            return { ...schedule, success: false };
                        }
                    })
                );
                let { request } = await api.v1.me.rental.requests.putCancel({ _id });

                this.$emit("input", request);
            } finally {
                this.loading = false;
            }
        },

        alert() {
            if (this.value.state == RENTAL_SCHEDULE_STATES.CANCELED.value) return;
            if (this.value.state == RENTAL_SCHEDULE_STATES.REJECTED.value) return;
            if (!this.$dayjs().isBefore(this.value.date, "date")) return;

            if (this.hasDatePassed) {
                alert("신청 취소는 전일 18시 이전까지만 가능합니다");
            }
        },
    },
};
</script>

<style></style>
