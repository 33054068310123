var render = function render(){
  var _vm$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-sheet', {
    staticClass: "user-card rounded-sm pa-20 pa-md-40",
    attrs: {
      "color": "grey lighten-5"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    staticClass: "user-card__avatar",
    attrs: {
      "src": "/images/icon/icon-avatar.svg"
    }
  })], 1), _c('v-col', [_c('div', {
    staticClass: "page-text font-weight-medium"
  }, [_c('span', [_vm._v(_vm._s(((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.name) || "회원"))]), _c('span', [_vm._v("님 안녕하세요.")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }